.benefit-reconciliation-page {
	.actions-banner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;

		.month-actions,
		.document-actions {
			display: flex;
			align-items: center;
			min-height: 38px;
		}

		.month-actions form {
			display: inline-flex;
			align-items: center;
			flex-shrink: 0;

			label {
				margin: 0;
				cursor: pointer;
				font-weight: $regular;
			}

			input[type='checkbox']:checked {
				background-color: $green-base;
				border-color: $green-base;
			}
		}

		.form-with-hidden-submit {
			button[type='submit'] {
				display: none;
				width: 0;
			}
		}

		.vertical-line {
			border-left: 1px solid $grey-base;
			height: 20px;
			margin: 0 12px;
		}

		select {
			height: 38px;
			min-width: 200px;
			padding: 0 15px;
			background-color: $white-base;
		}

		.secondary-text {
			margin: 0;
			line-height: normal;
		}

		.status {
			font-size: $s-tiny;
			font-weight: $semibold;
			padding: 4px 8px;

			&.green {
				background-color: $green-light;
			}
			&.red {
				background-color: #faeceb;
			}
			&.blue {
				background-color: #ecf2f8;
			}
		}
	}
}

.document-name {
	display: inline-block;
	max-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: bottom;

	&:hover {
		cursor: default;
	}
}
