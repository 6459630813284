/*-----------------------
Column Width
-----------------------*/

.row {
	width: 100%;

	@include tablet {
		display: flex;
		gap: 24px;
	}

	@for $i from 1 through 12 {
		.col-#{$i} {
			@include tablet {
				width: calc((($i/12) * 100%) - 12px);
			}
		}
	}
}
