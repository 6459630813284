.members {
	.card-warning {
		margin: 0 0 28px 0;
	}
}

.members-page {
	.card-warning {
		margin-bottom: 24px;
	}
}

.manage-members {
	.actions {
		margin: 0 100px;
		text-align: right;
		margin-bottom: 32px;
	}

	.vertical-line {
		margin: 0 12px;
		height: 22px;
	}

	.content-header {
		display: flex;
		flex-direction: column;

		.header-title {
			width: 100%;
		}

		.details {
			margin-top: 5px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.manage-details {
				width: 57%;
			}

			.action-details {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 30px;
			}
		}

		p {
			color: $grey-dark;
			font-size: $s-small;
			font-weight: 500;
			line-height: 20px;

			&.secondary-text {
				text-align: right;
				font-size: $s-tiny;
				line-height: 16px;
			}
		}
	}
}
