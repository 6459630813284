@keyframes appear-then-fade {
	0%,
	100% {
		opacity: 0;
	}
	5%,
	60% {
		opacity: 1;
	}
}
