.plan-details-page {
	.page-actions {
		display: flex;
		border-bottom: 1px solid $grey-base;
		padding: 1.25rem 1.75rem;
		justify-content: space-between;
	}

	.fiscal-year-actions {
		display: flex;
	}

	.backlink {
		display: flex;
		align-items: center;
		gap: $spacing-xx-small;

		img {
			width: 20px;
		}
	}

	.fiscal-year-text {
		display: flex;
		align-items: center;
		gap: $spacing-xx-small;

		label {
			margin: 0;
		}
	}
	.d-none {
		display: none;
	}

	.close-fiscal-year {
		padding: 0 1rem;
		border-right: 1px solid #dbdbdb;
	}

	.select-dropdown {
		display: inline-flex;
		align-items: center;
		gap: $spacing-x-small;
		padding-left: 1rem;

		label {
			text-align: right;
			font-size: $s-base;
			font-style: normal;
			font-weight: $medium;
			line-height: $base-line-small;
			margin: 0;
		}

		ul {
			position: absolute;
			background-color: $white-base;
			width: 100px;
			padding: 8px 0;
			flex-shrink: 0;
			border: 1px solid $grey-base;
			border-radius: 8px;
			top: 130px;
			right: 28px;

			li {
				padding-left: 1rem;
			}
		}

		button {
			background-color: transparent;
			background-image: url('../images/icons/chevron-down-outline.svg');
			background-position: right 15px top 50%;
			background-repeat: no-repeat;
			background-size: 20px;
			cursor: pointer;
			width: 100px;
			height: 36px;
			flex-shrink: 0;
			padding-right: 32px;
			text-overflow: ellipsis;
			appearance: none;
			box-sizing: border-box;
			color: $text-color;
			transition: all 0.3s ease-in-out;
			@include input-border;
			@include font-type($primary-sans-serif, normal, 400);
			@include s-base;
			line-height: 1;
			-webkit-appearance: none;
			-moz-appearance: none;
			-webkit-font-smoothing: antialiased;
		}
	}

	.plan-info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding: 1.75rem;
		border-bottom: 1px solid $grey-base;
		gap: $spacing-medium;

		h1 {
			font-size: 1.5rem;
		}

		.plan-location {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			margin-top: $spacing-x-small;
			color: $grey-dark;
			font-size: $s-small;

			img {
				width: 20px;
			}
		}

		.plan-details {
			display: flex;
			align-items: center;

			dl {
				padding: 0 1rem;
				border-right: 1px solid $grey-base;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					border-right: none;
				}
			}

			dt {
				font-size: $s-tiny;
				color: $grey-dark;
			}

			dd {
				font-size: $s-bigger;
				font-weight: $medium;
			}

			.status-renderer {
				font-size: $s-tiny;
				margin-top: $spacing-xx-small;
			}
		}

		@include tablet-large {
			flex-wrap: nowrap;
		}
	}
}
