//Sizing
$spacing-xx-small: 4px;
$spacing-x-small: 8px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-big: 32px;
$spacing-bigger: 48px;
$spacing-large: 64px;
$spacing-x-large: 80px;
$spacing-xx-large: 112px;
