// Base Typography
// ========================================

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	width: 100vw;
}

body {
	@include font-type($primary-sans-serif, normal, normal);
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include font-type($primary-sans-serif, normal, $regular);
	color: $text-color;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	line-height: 1.1;

	a {
		@include font-type($primary-sans-serif, normal, $regular);

		&:hover {
			text-decoration: underline;
		}
	}

	strong {
		font-weight: 800;
	}
}

h1 {
	@include rem(font-size, 30px);
	font-weight: $bold;

	@include tablet {
		@include rem(font-size, 36px);
	}
}

h2 {
	font-size: $font-size-base * 1.75;
	@include tablet {
		font-size: $font-size-base * 2.5;
	}
}
h3 {
	font-size: $font-size-base * 1.5;
	@include tablet {
		font-size: $font-size-base * 1.75;
	}
}
h4 {
	font-size: $font-size-base * 1.25;
	@include tablet {
		font-size: $font-size-base * 1.5;
	}
}
h5 {
	font-size: $font-size-base * 1;
	@include tablet {
		font-size: $font-size-base * 1.25;
	}
}
h6 {
	font-size: $font-size-base - 2;
	@include tablet {
		font-size: $font-size-base * 1;
	}
}

p {
	font-weight: normal;
	font-style: normal;
	@include font-type($primary-sans-serif, normal, $regular);
	@include s-small;
	line-height: 1.5;
	color: $text-color;

	&:last-child {
		@include rem(margin-bottom, 0);
	}

	a {
		@include s-small;
	}
}

li,
dd {
	color: $text-color;
	@include s-base;
	@include font-type($primary-sans-serif, normal, normal);
	list-style: none;
	line-height: $base-line-loose;
}

a {
	color: $text-color;
	cursor: pointer;
	@include s-base;
	@include font-type($primary-sans-serif, normal, $semibold);
	transition: all 0.3s ease-in-out;
	border-bottom: none;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $black-dark;
		text-decoration: underline;
	}
}

blockquote,
cite {
	color: $text-color;
}

strong,
b {
	font-weight: $semibold;
}
i,
em {
	font-style: italic;
}

u {
	text-decoration: underline;
}

hr {
	@include rem(margin-bottom, 16px);
}

sup {
	font-size: 50%;
	vertical-align: super;
}

ol.list {
	li {
		list-style-type: decimal;
	}
}

hr {
	border: none;
	border-top: 1px solid $grey-base;
}
