.sidebar {
	border-right: 1px solid $grey-base;
	height: 100vh;
	overflow: auto;
	position: fixed;
	width: 250px;

	.sidebar-inner {
		display: flex;
		flex-direction: column;
		height: calc(100% - 74px);
		justify-content: space-between;
	}

	ul {
		padding: 12px;

		li a {
			align-items: center;
			display: flex;
			font-weight: $regular;
			@include s-small;
			padding: 6px 16px;

			img {
				margin-right: 8px;
				width: 20px;
			}

			&.active {
				background-color: $black-light;
				border-radius: 4px;
				position: relative;

				&:before {
					background-color: $black-base;
					border-radius: 1000px;
					content: '';
					display: inline-block;
					height: 20px;
					left: 0px;
					position: absolute;
					width: 4px;
				}
			}
		}
	}

	.sidebar-footer {
		border-top: 1px solid $grey-base;
	}
}

.logo-block {
	border-bottom: 1px solid $grey-base;
	height: 72px;
	padding: 24px 28px;

	.logo {
		height: 24px;
		width: auto;
	}
}
