.collapsible-stack {
	padding-bottom: $spacing-medium;

	section header {
		background-color: $blue-grey-base;
		padding: 12px;
		margin-bottom: $spacing-small;

		&.primary {
			background-color: $blue-grey-dark;
		}

		&.primary.previous-year {
			margin-bottom: 0;
		}

		h2,
		h3 {
			font-size: $s-medium;
			font-weight: $semibold;
		}
	}

	h4 {
		font-size: $s-medium;
		font-weight: $semibold;
		margin: 24px 0 8px;
		padding-left: 12px;
	}

	dl {
		display: grid;
		grid-template-columns: 3fr 1fr;
		align-items: center;
		padding: 12px;
		font-weight: $medium;
		color: $black-base;
		border-bottom: 1px solid $grey-base;

		&:last-of-type {
			border-bottom: none;
		}

		dt.subitem {
			padding-left: 20px;
		}

		dd {
			text-align: right;
			font-weight: $medium;
			min-height: 32px;
		}

		dt.align-center {
			font-weight: $bold;
		}

		.align-center {
			text-align: center;
		}
	}

	.collapsible-icon {
		width: 24px;
	}

	header.collapsible {
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 12px;
	}

	.hidden {
		display: none;
	}
}

.collapsible-stack.edit-form {
	h4 {
		margin: 0;
		padding-left: 0;
		margin-bottom: $spacing-small;
	}

	section.block {
		padding: $spacing-medium 0;
		border-bottom: 1px solid $grey-base;

		&:first-of-type {
			padding-top: 0;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}
}
