@use '~ag-grid-community/styles' as ag;

@include ag.grid-styles(
	(
		theme: alpine,
		--ag-border-color: $grey-base,
		--ag-secondary-border-color: $grey-base,
		--ag-header-column-resize-handle-color: $grey-base,
		--ag-value-change-value-highlight-background-color: $grey-light,
	)
);

.ag-theme-alpine.table {
	height: 590px;
	width: 100%;

	.ag-root-wrapper.ag-layout-normal {
		border-radius: 15px;
		border-color: $grey-base;
	}

	.ag-center-cols-container {
		min-width: 100%;
	}

	.ag-header .ag-header-cell-text,
	.ag-cell,
	.ag-paging-panel {
		@include font-type($primary-sans-serif, normal, $medium);
		color: $black-base;
	}

	.ag-header {
		border-color: $grey-base;
		background-color: $white-base;
		height: 64px !important;
		min-height: 64px !important;

		.ag-header-cell {
			height: 64px !important;
		}

		.ag-header-cell-text {
			@include s-tiny;
			color: #3b3b3b;
		}
	}

	.ag-cell {
		padding-top: 3px;
		@include s-small;

		img {
			width: 20px;
			padding-top: 10px;
		}
	}

	.ag-paging-panel {
		justify-content: space-between;
		position: relative;
		top: -1px;
		border-color: $grey-base;

		.ag-paging-row-summary-panel-number,
		.ag-paging-number {
			font-weight: $medium;
		}
	}
}

//Status
.status {
	background-color: $white-dark;
	border-radius: 20em;
	padding: 2px 8px;

	&:before {
		border-radius: 20em;
		display: inline-block;
		content: '';
		height: 10px;
		margin-right: 5px;
		width: 10px;
	}

	&.blue:before {
		background-color: $blue-base;
	}

	&.purple:before {
		background-color: $purple-base;
	}

	&.magenta:before {
		background-color: $magenta-base;
	}

	&.indigo:before {
		background-color: $indigo-base;
	}

	&.orange:before {
		background-color: $orange-base;
	}

	&.melon:before {
		background-color: $melon-base;
	}

	&.green:before {
		background-color: $green-base;
	}

	&.red:before {
		background-color: $red-base;
	}

	&.yellow:before {
		background-color: $yellow-base;
	}

	&.grey:before {
		background-color: $grey-base;
	}

	&.blue-grey:before {
		background-color: $blue-grey-base;
	}
}

//Table Actions
.table-actions {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}

// Pinned Data
.ag-pinned-right-cols-container {
	margin-right: 0 !important;
}

.shadow-column-right {
	box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.05);
}

.shadow-column-left {
	box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.05);
}

.cell-actions,
.cell-warnings {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;

	button {
		background: none;
		border: none;
		padding: 0;
		background-size: 27px 27px;
		background-repeat: no-repeat;
		width: 27px; /* Adjust width and height as needed */
		height: 27px;
		cursor: pointer;

		&:nth-of-type(2) {
			margin-left: 5px;
		}
	}

	.btn-checkmark-default {
		background-image: url('../images/icons/checkmark-default.svg');
	}
	.btn-checkmark-clicked {
		background-image: url('../images/icons/checkmark-clicked.svg');
	}
	.btn-duplicate-default {
		background-image: url('../images/icons/duplicate-default.svg');
	}
	.btn-duplicate-clicked {
		background-image: url('../images/icons/duplicate-clicked.svg');
	}
	.btn-warning-circle {
		background-image: url('../images/icons/warning-circle-icon.svg');
	}

	/* Hover states */
	.btn-duplicate-default:hover {
		background-image: url('../images/icons/duplicate-hover.svg');
	}
	/* Hover states */
	.btn-checkmark-default:hover {
		background-image: url('../images/icons/checkmark-hover.svg');
	}
}

.cell-warnings {
	align-items: flex-start;

	.btn-warning-circle-wrapper {
		display: flex;
		align-items: center;
		height: 40px;

		button {
			background-size: 16px;
			background-position: center;
			width: 24px;
			height: 24px;
		}
	}
}

.new-member,
.missing-member {
	padding-left: 26px;

	&:before {
		background-size: 21px;
		content: '';
		height: 21px;
		transition: all 0.2s ease-in;
		top: 2px;
		left: 2px;
		text-align: center;
		width: 21px;
		display: block;
		position: absolute;
		top: 12px;
		left: 15px;
	}
}

.new-member {
	&:before {
		background-image: url('../images/icons/new-member.svg');
	}
}

.missing-member {
	&:before {
		background-image: url('../images/icons/support.svg');
	}
}

.ag-cell {
	&.with-warning {
		width: 100%;

		border: 1px solid $yellow-warning;
		background: linear-gradient(
				0deg,
				rgba(251, 188, 5, 0.08) 0%,
				rgba(251, 188, 5, 0.08) 100%
			),
			$white-base;

		&:after {
			background-image: url('../images/icons/warning-icon.svg');
			background-size: 21px;
			content: '';
			height: 21px;
			transition: all 0.2s ease-in;
			top: 2px;
			left: 2px;
			text-align: center;
			width: 21px;
			display: block;
			position: absolute;
			top: 12px;
			left: 160px;
		}
	}

	&.with-comment {
		width: 100%;

		&:after {
			background-image: url('../images/icons/comments.svg');
			background-size: 21px;
			content: '';
			height: 21px;
			transition: all 0.2s ease-in;
			text-align: center;
			width: 21px;
			display: block;
			position: absolute;
			top: 0px;
			left: 160px;
		}
	}

	&.with-error {
		width: 100%;

		border: 1px solid $red-base;
		background: linear-gradient(
				0deg,
				rgba(202, 66, 55, 0.1) 0%,
				rgba(202, 66, 55, 0.1) 100%
			),
			$white-base;

		&:after {
			background-image: url('../images/icons/error-icon.svg');
			background-size: 21px;
			content: '';
			height: 21px;
			transition: all 0.2s ease-in;
			top: 2px;
			left: 2px;
			text-align: center;
			width: 21px;
			display: block;
			position: absolute;
			top: 12px;
			left: 160px;
		}
	}

	&.partial-border-right {
		border-right: 1px solid $grey-base;
	}
}

.shaded-row {
	opacity: 0.5;

	.with-warning,
	.with-error {
		border: none;
		background: none;

		&:after {
			content: none;
		}
	}
}

.ag-cell-inline-editing {
	&.with-warning,
	&.with-error,
	&.with-comment {
		&:after {
			content: none;
		}
	}
}
