.card {
	background-color: $white-base;
	border: 1px solid $grey-base;
	border-radius: 15px;
	padding: 32px;
	width: 100%;

	&.small {
		max-width: 480px;
	}

	&.medium {
		max-width: 876px;
	}

	form + a {
		display: block;
		margin: 16px auto 0px;
		width: fit-content;
	}

	.no-label-field {
		padding-top: 20px;
		display: flex;
		align-items: center;
	}
}

.card-header {
	@include tablet {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	h1,
	h2 {
		font-weight: $semibold;
		@include s-medium;
		text-align: left;
		width: 100%;
	}

	span {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.card-header-icon {
		width: 30px;
		margin-right: $s-small;
	}

	.button-with-icon {
		flex-shrink: 0;
	}
}

.icon-wrapper {
	border: 1px solid #dbdbdb;
	border-radius: 5px;
	padding: 5px;
}

.card-header + .card-content {
	margin-top: 24px;
}

.card-actions {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;

	.button-primary {
		margin-left: 32px;
		width: auto;
	}
}

.card + .card {
	margin-top: 24px;
}

.pill-list {
	display: flex;
	gap: 8px;
	flex-flow: wrap;

	li {
		background-color: $white-dark;
		border-radius: 20em;
		font-weight: $medium;
		@include s-small;
		line-height: normal;
		padding: 8px 16px;
	}
}

.card-warning {
	align-items: center;
	display: flex;
	border-radius: 15px;
	border: 1px solid #bf713c;
	background: #fffdf6;
	padding: 15px;

	p {
		margin: 0 !important;
	}

	img {
		margin-right: 8px;
		width: 24px;
	}
}

.card-error {
	align-items: center;
	display: flex;
	border-radius: 15px;
	border: 1px solid #ca4237;
	background: #fdf7f7;
	padding: 15px;
	gap: 10px;

	p {
		margin: 0 !important;
		width: 100%;
	}

	img {
		margin-right: 8px;
		width: 24px;
	}

	.icon-link {
		display: flex;
		align-items: center;
		margin-top: 0px;
	}
}

.card-warning-link:hover {
	text-decoration: none;

	strong {
		text-decoration: underline;
	}
}

.nested-form {
	.add-record {
		width: 100%;
		margin-bottom: 28px;
	}

	.remove-record {
		font-size: $s-small;
		display: flex;
		flex: 1;
		justify-content: flex-end;
	}
}
