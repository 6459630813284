//BORDERS
$grey-border: 1px solid $grey-light;
$black-border: 1px solid $grey-base;
$white-border: 1px solid $white-base;

$focus-on-white: 1px dotted $grey-base;

@mixin center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

@mixin base-padding {
	@include rem(padding, 32px 16px);

	@include tablet {
		@include rem(padding, 60px 32px);
	}
}

@mixin small-padding {
	@include rem(padding, 16px);

	@include tablet {
		@include rem(padding, 32px);
	}
}

@mixin large-padding {
	@include rem(padding, 30px 20px);

	@include tablet {
		@include rem(padding, 30px);
	}
}

@mixin vertical-center {
	transform: translateY(-50%);
	position: absolute;
	top: 50%;
}

@mixin horizontal-center {
	transform: translateX(-50%);
	position: absolute;
	left: 50%;
}

@mixin vertical-horizontal-center {
	@include vertical-center;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
}

img {
	width: 100%;
}

@mixin clickable-block {
	display: inline-block;
	height: 100%;
	width: 100%;
}

@mixin mobile-hide {
	display: none;
	@include tablet {
		display: block;
	}
}

@mixin desktop-hide {
	@include tablet {
		display: none;
	}
}

@mixin outline-dark {
	outline: 1px dotted $black-base;
	outline-offset: 2px;
}

@mixin outline-light {
	outline: 1px dotted $white-base;
	outline-offset: 2px;
}

.horizontal-divider {
	border-bottom: $grey-border;
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin flex-grid($cols, $gutter: 0) {
	display: flex;
	flex-wrap: wrap;

	> * {
		// Reset to prevent external styles break the grid.
		margin-left: 0;
		margin-bottom: 0;
		$item-width: calc((100% / #{$cols}) - #{$gutter} + (#{$gutter} / #{$cols}));
		margin-right: $gutter;
		width: $item-width;
		box-sizing: border-box;

		// We add the gutter for all items except the first row.
		&:nth-child(n + #{$cols + 1}) {
			margin-top: $gutter;
		}

		// When we reach the cols per line, the last
		// col item must not have margin right.
		&:nth-child(#{$cols}n) {
			margin-right: 0;
		}
	}
}
