@mixin input-border {
	border: 1px solid $grey-base;
	border-radius: 10px;
}

/*-----------------------
Fieldset
-----------------------*/
.field {
	display: inline-block;
	@include rem(margin-bottom, 16px);
	position: relative;
	width: 100%;
}

/*-----------------------
Labels
-----------------------*/
label,
legend {
	color: $text-color;
	display: inline-block;
	@include font-type($primary-sans-serif, normal, $semibold);
	@include s-small;
	@include rem(margin-bottom, 6px);
	text-align: left;
	width: 100%;

	i {
		font-style: italic;
		font-weight: $regular;
	}

	a {
		float: right;
		@include s-small;
		font-weight: normal;
	}
}

/*-----------------------
Inputs
-----------------------*/

input,
select,
textarea,
.pill-input div {
	appearance: none;
	background-color: transparent;
	box-sizing: border-box;
	color: $text-color;
	transition: all 0.3s ease-in-out;
	@include input-border;
	@include font-type($primary-sans-serif, normal, 400);
	@include s-base;
	@include rem(padding, 15px);
	line-height: 1;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	@include rem(height, 48px);

	&::placeholder {
		color: $grey-dark;
	}
}
input:disabled {
	background-color: $grey-light;
	cursor: not-allowed;
}

/*-----------------------
Textarea
-----------------------*/
textarea,
textarea[type='text'] {
	height: 100px;
	resize: vertical;
}

/*-----------------------
Select
-----------------------*/
select {
	background-image: url('../images/icons/chevron-down-outline.svg');
	background-position: right 15px top 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	cursor: pointer;
	@include rem(padding, 15px 32px 15px 15px);
	text-overflow: ellipsis;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	option:disabled {
		color: $grey-light;
		font-style: italic;
	}
}

/*-----------------------
Checkbox
-----------------------*/
.checkbox {
	display: flex;
	flex-direction: row-reverse;

	label {
		cursor: pointer;
		margin-top: 2px;
	}
}

input[type='checkbox'] {
	-webkit-appearance: none;
	appearance: none;
	transition: all 0.3s ease-in-out;
	background-color: $white-base;
	border: 1px solid $grey-base;
	cursor: pointer;
	position: relative;
	@include rem(height, 20px);
	@include rem(width, 20px);
	@include rem(min-width, 20px);
	outline: none;
	display: inline-block;
	@include rem(margin, 0px 8px 0px 0px);
	border-radius: 4px;
	padding: 0px;

	&:checked {
		background-color: $primary-base;
		border-color: $primary-base;
		&:after {
			background-image: url('../images/icons/checkmark-white.svg');
			background-size: 15px;
			content: '';
			display: block;
			height: 16px;
			transition: all 0.2s ease-in;
			position: absolute;
			top: 2px;
			left: 2px;
			text-align: center;
			width: 17px;
			z-index: 3;
		}
	}
}

/*-----------------------
Radio Button
-----------------------*/
.radio-button {
	.radio-option {
		margin-top: 6px;
		display: flex;

		label {
			cursor: pointer;
			margin-top: 2px;
		}
	}
}

input[type='radio'] {
	-webkit-appearance: none;
	appearance: none;
	transition: all 0.3s ease-in-out;
	background-color: $white-base;
	border: 1px solid $grey-base;
	cursor: pointer;
	position: relative;
	@include rem(height, 20px);
	@include rem(width, 20px);
	outline: none;
	display: inline-block;
	@include rem(margin, 0px 8px 0px 0px);
	border-radius: 4px;
	padding: 0px;
	border-radius: 20em;
	flex: none;

	&:checked {
		background-color: $primary-base;
		border-color: $primary-base;
		&:after {
			background-image: url('../images/icons/checkmark-white.svg');
			background-size: 15px;
			content: '';
			display: block;
			height: 16px;
			transition: all 0.2s ease-in;
			position: absolute;
			top: 2px;
			left: 2px;
			text-align: center;
			width: 17px;
			z-index: 3;
		}
	}
}

/*-----------------------
Errors
-----------------------*/

.field_with_errors {
	input,
	select,
	textarea {
		background-color: $red-light;
		border-color: $red-base;
	}
}

span.error-message {
	color: $red-base;
	display: block;
	@include s-small;
	@include rem(margin-top, 8px);
}

/*-----------------------
Multiselect (lots of library overrides)
-----------------------*/
[id*='SearchableSelect-react-component-'] {
	[class*='-control'] {
		@include input-border;
		min-height: 3rem;
	}

	input {
		height: auto;
	}

	[class*='-Input'] {
		margin: 0px;
		padding: 0px;
	}

	[class*='-Input'] {
		margin: 0px;
		padding: 0px;
	}

	[class*='-multiValue'] {
		background-color: $white-dark !important;
		border-radius: 20em;
	}

	[class*='-MultiValueRemove'] {
		cursor: pointer;
		padding: 8px;

		&:hover {
			border-radius: 20em;
			background-color: transparent;

			svg {
				fill: $red-base;
			}
		}
	}

	[class*='-MultiValueGeneric'] {
		color: $black-base !important;
		font-weight: $medium;
		padding: 8px 0px 8px 16px;
	}
}

.valuation-report-configuration {
	.toggle-buttons {
		display: flex;
		padding: 32px;
		width: 100%;
	}

	.toggle-selections {
		display: flex;
		border-radius: 8px;
		border: 1px solid $grey-base;
		height: 36px;

		label {
			float: left;
			width: 61px;
			height: 36px;
			border-radius: 8px;
			border-bottom: 1px solid $grey-base;
			background-color: $white-base;
			color: $black-base;
			flex-shrink: 0;

			span {
				color: $black-base;
				margin: 0 20px 15px;
			}
		}

		input[type='radio'] {
			background-color: $white-base;
			border-color: $white-base;
			transition: none;
			height: 0;
			width: 0;

			&:checked {
				background-color: $black-base;
				border-color: $black-base;
				&:after {
					background-image: none;
				}
			}
		}

		.yes-label {
			border-radius: 8px 0px 0px 8px;
		}

		.no-label {
			border-radius: 0px 8px 8px 0px;
		}

		label:has(input[type='radio']:checked) {
			background-color: $black-base;
			border-radius: 8px;

			span {
				color: $white-base;
			}
		}
	}
}

.file-upload-input {
	width: 444px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	text-decoration: underline;
}

.file-upload-label {
	text-align: center;
	text-decoration: underline;
	cursor: pointer;
}

.file-upload-container {
	border-radius: 8px;
	border: 1px solid #dedede;
	background: $white-base;
	display: flex;
	width: 100%;
	padding: 24px 112px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;

	img {
		width: 28px;
	}
}

.file-upload-accepted {
	display: block;
	margin-top: 5px;
	font-size: 0.8em;
	color: #666;
}

.file-import-details {
	display: none;
	height: 60px;
	flex-shrink: 0;
	border-radius: 8px;
	border: 1px solid #dedede;
	background: $white-base;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px 15px 15px;

	.file-name {
		background-color: none;
	}

	button {
		background: none;
		border: none;
		font-size: 20px;
	}
}
