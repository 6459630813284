.page-header {
	align-items: center;
	background-color: $white-base;
	border-bottom: 1px solid $grey-base;
	display: flex;
	height: 72px;
	padding: 24px 28px;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 1;

	.logo-block {
		p {
			@include s-base;
			font-weight: $semibold;
		}
	}

	&.with-actions {
		justify-content: space-between;

		div {
			display: flex;
			align-items: center;
		}
	}

	button {
		margin-left: 20px;
	}
}
