.confidence-analysis-container {
	padding: 20px;
	margin: auto;
	width: 90%;
	background-color: #f9f9f9;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

	table {
		width: 100%;
		border-collapse: collapse;
	}

	table,
	th,
	td {
		border: 1px solid black;
	}

	th,
	td {
		padding: 8px;
		text-align: left;
	}
}
