.empty-state-box {
	height: 400px;
	width: 100%;
	border-radius: 15px;
	border: 1px solid #dbdbdb;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 32px;
	}

	h2 {
		font-size: $s-medium;
		font-weight: $semibold;
		line-height: 20px;
		margin-top: 22px;
		margin-bottom: 10px;
	}

	p {
		font-size: $s-small;
		width: 45%;
		text-align: center;
		font-weight: $medium;
		line-height: 20px;
		color: #595959;
	}

	.card-warning-link {
		margin-top: $spacing-medium;
	}

	.card-error {
		p {
			width: 100%;
			align-items: center;
			display: flex;
		}
	}
}
