// Typography Variables
// ========================================

// Font Families

$primary-sans-serif: 'Inter', sans-serif;

// Sizes
$font-size-base: 16px;

$s-giga: 60px;
$s-mega: 45px;

$s-huge: 36px;
$s-large: 25px;
$s-bigger: 22px;
$s-big: 20px;
$s-medium: 18px;
$s-base: 16px;
$s-small: 14px;
$s-tiny: 12px;
$s-mini: 9px;

// Line Heights
$base-line-height: $font-size-base * 1.4;
$base-line-loose: $font-size-base * 2;
$base-line-small: $font-size-base;

// Font Weights
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
