.switch {
	display: flex;

	label {
		display: flex;
		align-items: center;
		gap: $spacing-x-small;
		margin: 0;
		cursor: pointer;
	}

	.switch-box {
		position: relative;
		display: inline-block;
		width: 44px;
		height: 24px;

		/* Hide default HTML checkbox */
		input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 24px;
			background-color: $grey-base;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: '';
			height: 20px;
			width: 20px;
			left: 2px;
			bottom: 2px;
			border-radius: 50%;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider img {
			position: absolute;
			bottom: 5px;
			left: 5px;
			width: 14px;
			height: 14px;
			opacity: 0;
			transition: 0.4s;
		}

		input:checked {
			& + .slider {
				background-color: $green-base;

				&:before,
				img {
					-webkit-transform: translateX(20px);
					-ms-transform: translateX(20px);
					transform: translateX(20px);
					opacity: 1;
				}
			}
		}
	}

	.text {
		font-size: $font-size-base;
		font-weight: $regular;
	}
}
