.toast-message {
	border-radius: 10px;
	padding: 16px;
	position: fixed;
	top: 32px;
	right: 32px;
	width: 350px;
	z-index: 2;

	align-items: center;
	display: flex;
	justify-content: space-between;
	animation: appear-then-fade 5s both;

	.text-block {
		display: flex;
		margin-right: 32px;

		p {
			font-weight: $semibold;
			line-height: 1.3;
			@include s-small;
		}
	}

	.icon {
		border-radius: 3px;
		height: 18px;
		margin-right: 8px;
		width: 18px;
	}

	.close-toast {
		appearance: none;
		-webkit-appearance: none;
		background-color: transparent;
		border: none;
		flex: none;
		padding: 0px;
		height: 18px;
	}
}

.notice {
	border: 1px solid $green-base;
	background-color: $green-light;

	.icon {
		background-color: $green-base;
	}
}

.alert {
	border: 1px solid $red-base;
	background-color: $red-light;

	.icon {
		background-color: $red-base;
	}
}
