.ledger {
	min-height: 100vh;

	.table {
		height: 100%; // paired with domLayout="autoHeight"

		--ag-borders: none;
		--ag-row-border-style: solid;
		--ag-row-border-width: 1px;
		--ag-row-border-color: #ececec;

		.ag-header {
			min-height: 50px !important;
			height: 50px !important;
		}

		.ag-header-row {
			border-bottom: solid 1px $grey-base;
		}

		.ag-full-width-row {
			border: none;
		}
	}

	.actions {
		margin: 0 100px;
		text-align: right;
		margin-bottom: 32px;
	}

	.vertical-line {
		margin: 0 12px;
		height: 22px;
	}

	.content-header {
		display: flex;
		flex-direction: column;
		margin: 0;
		width: auto;

		.header-title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.ledger-content {
		padding: 40px;
		background: $white-base;
		margin: 0 100px $spacing-bigger;
		border-radius: 15px;
		border: 1px solid $grey-base;

		.content-header {
			margin-bottom: $spacing-medium;
		}
	}

	.no-sidebar-content {
		padding: 24px 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.actions-banner {
		margin-bottom: 0;
		background: $white-base;
		width: auto;
		padding: 0;

		.vertical-line {
			border-left: 1px solid $grey-base;
			height: 20px;
			margin: 0 12px;
		}

		.secondary-text {
			margin: 0;
			max-width: 450px;
			line-height: normal;
		}

		.status {
			font-size: $s-tiny;
			font-weight: $semibold;
			padding: 4px 8px;

			&.green {
				background-color: $green-light;
			}
			&.red {
				background-color: #faeceb;
			}
			&.blue {
				background-color: #ecf2f8;
			}
		}
	}

	.edit-bank-statement-total {
		display: flex;
		align-items: center;

		button.link-primary {
			&:before {
				background-size: 12px;
				width: 12px;
				height: 12px;
				margin-top: 1px;
			}

			&:hover {
				text-decoration: underline;
			}

			&:hover:before {
				filter: none;
			}
		}

		form {
			display: flex;
			align-items: center;
			gap: $spacing-small;
		}

		input {
			width: 160px;
			height: 36px;
			margin-left: $spacing-small;
			background-color: $white-base;
		}
	}

	footer {
		margin: 0 100px $spacing-bigger;

		.annual-total {
			font-weight: $semibold;
		}
	}
}
