.flatfile_displayAsModal {
	width: 100% !important;
	height: 100vh !important;
	padding: 100px !important;
}

.flatfile_iframe-wrapper.flatfile_displayAsModal {
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(12, 12, 12, 0.6);
	backdrop-filter: blur(2px);

	.flatfile_iFrameContainer {
		height: 90vh !important;
	}
}

.flatfile-close-button {
	top: 5px !important;
	right: 30px !important;
	height: 25px !important;
	width: 25px !important;
}
