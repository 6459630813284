// Buttons Component Styles
// ========================================

//Default
button,
input[type='submit'] {
	appearance: none;
	-webkit-appearance: none;
	cursor: pointer;
	@include font-type($primary-sans-serif, normal, $semibold);
	transition: all 0.1s ease-in-out;
}

// Main Button
@mixin button {
	display: inline-block;
	@include rem(padding, 12px 15px);
	@include s-base;
	transition: all 0.1s ease-in-out;
	line-height: normal;
	position: relative;
	text-align: center;
	text-decoration: none;
	-webkit-appearance: none;
	min-width: 150px;
	font-weight: $semibold;
	border-radius: 8px;
}

// Button Styles
@each $button in $buttons {
	$type: nth($button, 1);
	$bg-1: nth($button, 2);
	$border: nth($button, 3);
	$color: nth($button, 4);
	$hover-bg: nth($button, 5);
	$hover-color: nth($button, 6);

	.button-#{$type},
	a.button-#{$type} {
		background-color: $bg-1;
		border: 1px solid $border;
		color: $color;
		@include button;

		&:hover {
			background-color: $hover-bg;
			border: 1px solid $hover-bg;
			color: $hover-color;
			opacity: 1;
		}

		&:active {
			background-color: $hover-bg;
			border: 1px solid $hover-bg;
			color: darken($hover-color, 5%);
		}

		&.is-disabled {
			background-color: $bg-1;
			color: $color;
			cursor: not-allowed;
			opacity: 0.4;
		}

		&.in-progress {
			pointer-events: none;
			color: transparent;

			&:after {
				border: 2px solid $color;
				border-radius: 50%;
				border-right-color: transparent;
				border-top-color: transparent;
				content: '';
				display: block;
				height: 20px;
				animation: spin 0.5s linear infinite;
				margin: 0 calc((100% - 50px) / 2);
				position: absolute;
				top: 12px;
				width: 20px;
			}
		}
	}

	.button-with-icon,
	a.button-with-icon {
		width: 30px;
		height: 30px;
		padding: 0;
		@include s-small;
		min-width: inherit;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 20px;
			width: 20px;
		}

		&:hover {
			img {
				filter: brightness(0) invert(1);
				transition: all 0.1s ease-in-out;
			}
		}
	}

	// Links
	.link-#{$type} {
		color: $bg-1;
		display: inline-block;
		@include s-small;
		@include font-type($primary-sans-serif, normal, $semibold);
		transition: all 0.3s ease-in-out;
		text-decoration: none;
		appearance: none;
		border: none;
		padding: 0px;
		cursor: pointer;
		background-color: transparent;

		&:hover {
			color: $hover-bg;
		}
	}
}

// Button Sizes
.button-small,
a.button-small {
	@include rem(padding, 8px 16px);
	@include s-small;
	min-width: inherit;
}

.button-large,
a.button-large {
	@include rem(padding, 12px 24px);
	@include rem(font-size, 24px);

	@include tablet {
		@include rem(font-size, 32px);
	}
}

//Arrow
a.arrow-back {
	align-items: center;
	display: flex;
	margin: 0px 0px 16px;
	text-align: left;
	width: fit-content;

	&:before {
		content: '';
		display: inline-block;
		margin-right: 4px;
		background-image: url('../images/icons/arrow-left-outline.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		height: 20px;
		width: 20px;
	}
}

[class*='button-'].download,
[class*='link-'].download {
	align-items: center;
	display: flex;
	text-align: left;
	width: fit-content;

	&:before {
		content: '';
		display: inline-block;
		margin-right: 4px;
		background-image: url('../images/icons/download-outline.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		height: 20px;
		width: 20px;
	}
}

[class*='button-'].download {
	&:hover {
		&:before {
			filter: brightness(0) invert(1);
		}
	}
}

// import button
[class*='button-'].import,
[class*='link-'].import {
	align-items: center;
	display: flex;
	text-align: left;
	width: fit-content;

	&:not(.exclude-icon):before {
		content: '';
		display: inline-block;
		margin-right: 4px;
		background-image: url('../images/icons/import-outline.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		height: 20px;
		width: 20px;
	}

	&.button-primary:not(.exclude-icon):before {
		background-image: url('../images/icons/import-outline-white.svg');
	}

	&.button-primary-outline:last-of-type {
		margin-left: 12px;
	}
}

// import button
[class*='button-'].arrows,
[class*='link-'].arrows {
	align-items: center;
	display: flex;
	text-align: left;
	width: fit-content;

	&:before {
		content: '';
		display: inline-block;
		margin-right: 4px;
		background-image: url('../images/icons/two-way-arrows-line.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		height: 20px;
		width: 20px;
	}

	&.button-primary:before {
		background-image: url('../images/icons/two-way-arrows-line.svg');
	}
}

// button/link with icon
[class*='button-'].with-icon,
[class*='link-'].with-icon {
	align-items: center;
	display: flex;
	text-align: left;
	width: fit-content;

	&:before {
		content: '';
		display: inline-block;
		margin-right: 4px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		height: 20px;
		width: 20px;
	}

	&:hover {
		&:before {
			filter: brightness(0) invert(1);
		}
	}
}

[class*='button-'].edit-icon,
[class*='link-'].edit-icon {
	&:before {
		background-image: url('../images/icons/edit.svg');
	}
}

[class*='button-'].trash,
[class*='link-'].trash {
	&:hover {
		background-color: $black-base;
		opacity: 1;

		&:before {
			background-image: url('../images/icons/trash-white.svg');
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 5px;
		left: 5px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		height: 15px;
		width: 15px;
		background-image: url('../images/icons/trash.svg');
	}
}

// close
[class*='button'].close {
	align-items: center;
	display: flex;
	text-align: left;
	width: fit-content;
	border-radius: 5px;
	background-color: $white-base;
	border: 1px solid #b4b4b4;

	&:before {
		content: '';
		display: inline-block;
		background-image: url('../images/icons/close-outline.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 28px;
		height: 34px;
		width: 34px;
	}
}

[class*='button'].close {
	&:hover {
		background-color: $grey-light;
		color: $secondary-base;
		opacity: 1;

		&:before {
			filter: brightness(1) invert(1);
		}
	}
}

// Legend
[class*='button-'].legend {
	margin: 1.5em 0 1em 0;
	box-shadow: 10px 0 20px 0 rgba(0, 0, 0, 0.05);

	&:before {
		content: '';
		display: inline-block;
		margin-right: 4px;
		background-image: url('../images/icons/legend-icon.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 13px;
		height: 10px;
		width: 15px;
	}
}
