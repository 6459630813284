.modal-overlay {
	background: rgba(12, 12, 12, 0.6);
	backdrop-filter: blur(2px);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
}

// used for stimlulus modal
.modal-container {
	display: none;
}

.modal-content {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	width: 500px;
	min-height: 210px;
	background: white;
	border-radius: 15px;
	padding: 30px 20px;
	background: $white-base;

	h3 {
		color: $black-base;
		font-size: 24px;
		font-style: normal;
		font-weight: $semibold;
		line-height: normal;
		margin-bottom: 30px;
	}

	.col-12 {
		width: 100%;
	}
}

.modal-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button:first-of-type {
		margin-right: 12px;
	}

	.button-small {
		width: fit-content;
	}
}

.modal-close {
	position: absolute;
	top: 33px;
	right: 28px;
	border: none;
	background: none;
	cursor: pointer;
}

.modal-list {
	margin: 30px 0;
	&-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&-item:not(:nth-child(1)) {
		border-top: 1px solid $grey-base;
	}

	&-item-image {
		width: 40px;
		height: 40px;
		border-right: 1px solid $grey-base;
		padding: 10px;
		margin: 10px;
		margin-left: 0;
	}

	&-item-text {
		font-size: $s-small;
		line-height: $font-size-base;
		padding: 10px 0;
		margin: 0 10px;
	}
}

.checkbox-container {
	display: flex;
	align-items: center;
}
.checkbox-container label {
	order: 2;
	margin-bottom: 0;
	font-size: $s-small;
	font-weight: normal;
}
.checkbox-container input[type='checkbox'] {
	order: 1;
	margin-right: 10px;
}
