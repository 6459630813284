.auth-layout {
	background-color: #f6f8fb;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 32px;

	.card {
		.logo {
			height: 36px;
			margin-bottom: 32px;
			width: auto;
		}

		header {
			h1 + p {
				margin-top: 8px;
			}

			p + p {
				margin-top: 16px;
			}

			p {
				max-width: 350px;
			}

			ul li {
				@include s-small;
			}
		}

		header + form {
			margin-top: 32px;
		}
	}

	footer {
		margin-top: 16px;
		text-align: center;

		@include tablet {
			align-items: center;
			display: flex;
			width: auto;
		}

		* {
			display: inline-block;
			@include s-small;
			margin: 16px 16px 0px;

			@include tablet {
				margin: 0px 16px;
			}
		}
	}
}
