.tooltip {
	padding: $spacing-x-small;
	background-color: $black-base;
	color: $white-base;
	border-radius: 5px;
	font-size: $s-tiny;
	span {
		white-space: pre-wrap;
	}
}
