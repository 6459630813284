@mixin mobile {
	@media (max-width: 720px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 720px) {
		@content;
	}
}

@mixin tablet-large {
	@media (min-width: 1050px) {
		@content;
	}
}

@mixin tablet-landscape {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin tablet-landscape-and-height {
	@media (min-width: 1200px) and (min-height: 800px) {
		@content;
	}
}

@mixin desktop-wide {
	@media (min-width: 1600px) {
		@content;
	}
}
