// Typography Mixin
// ========================================

// Font Sizes
@mixin s-giga {
	@include rem(font-size, $s-giga);
}

@mixin s-mega {
	@include rem(font-size, $s-mega);
}

@mixin s-huge {
	@include rem(font-size, $s-huge);
}

@mixin s-large {
	@include rem(font-size, $s-large);
	@include rem(line-height, $base-line-height);
}

@mixin s-bigger {
	@include rem(font-size, $s-bigger);
	@include rem(line-height, $base-line-height);
}

@mixin s-big {
	@include rem(font-size, $s-big);
	line-height: 1.4;
}

@mixin s-medium {
	@include rem(font-size, $s-medium);
	line-height: normal;
}

@mixin s-base {
	@include rem(font-size, $s-base);
}

@mixin s-small {
	@include rem(font-size, $s-small);
}

@mixin s-tiny {
	@include rem(font-size, $s-tiny);
	@include rem(line-height, $base-line-height);
}

@mixin s-mini {
	@include rem(font-size, $s-mini);
	@include rem(line-height, $base-line-height);
}

// Font Styles
@mixin font-type($type, $style, $weight) {
	font-family: $type;
	font-style: $style;
	font-weight: $weight;
}
