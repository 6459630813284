//MAIN COLORS

//BLACK
$black-base: #0c0c0c;
$black-light: lighten($black-base, 90%);
$black-dark: #15191c;

//GREEN
$green-base: #0f9d58;
$green-light: #e7f5ee;
$green-dark: darken($green-base, 10%);

//GREY
$grey-lightest: #f6f6f6;
$grey-light: #f1f1f1;
$grey-base: #dbdbdb;
$grey-dark: #595959;

//RED
$red-base: #ca4237;
$red-light: #fff8f8;
$red-dark: darken($red-base, 10%);

//ORANGE
$orange-base: #ff6948;

//WHITE
$white-base: #ffffff;
$white-dark: #eeeeee;

//BLUE
$blue-base: #006ff9;
$blue-dark: darken($blue-base, 10%);
$blue-medium: #162d5e;
$blue-light: lighten($blue-base, 45%);
$blue-lightest: #f8fbff;

//BLUE SECONDARY
$blue-secondary-base: #4c73cd;
$blue-secondary-dark: darken($blue-secondary-base, 10%);

//BLUE-GREY
$blue-grey-base: #f6f8fb;
$blue-grey-dark: #e4e8f1;
$blue-grey-darker: darken($blue-grey-dark, 10%);
$blue-grey-alternative: #e5ecf6;

//YELLOW
$yellow-base: #f1d615;
$yellow-warning: #fbbc05;
$yellow-base-lighter: lighten($yellow-base, 30%);

//PRIMARY
$primary-base: $blue-base;
$primary-dark: darken($primary-base, 10%);

//SECONDARY
$secondary-base: $black-base;
$secondary-dark: darken($secondary-base, 10%);

//General
$text-color: $black-base;

//MELON
$melon-base: #ff0099;

$indigo-base: #7a71f6;
$magenta-base: #e37cff;
$purple-base: #aa71ff;
