.plan-page {
	.plan-cards {
		display: flex;
		flex-wrap: wrap;
		gap: $spacing-medium;

		@include tablet-large {
			flex-wrap: nowrap;
		}
	}

	.nav-cards {
		width: 100%;
	}

	.card {
		padding: $spacing-medium;

		h2 {
			font-weight: $semibold;
		}

		&.plan-details {
			h2 {
				font-size: $s-bigger;
			}
		}

		.secondary-text {
			@include s-tiny;
			margin: 0 0 $spacing-small;
		}

		dl {
			border-top: solid 1px $grey-base;
			margin-top: $spacing-small;

			dt {
				font-size: $s-tiny;
				color: $grey-dark;
				margin-top: $spacing-small;
			}

			dd {
				font-size: $s-small;
				font-weight: $medium;
				line-height: $base-line-height;
			}
		}

		.status-renderer {
			padding-top: $spacing-x-small;
		}

		.card-warning {
			margin: 16px 0 0;
			.secondary-text {
				line-height: 16px;
			}
		}
	}

	.panel-cta {
		margin-top: $spacing-medium;
	}
}
