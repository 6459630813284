#error_explanation {
	border-radius: 10px;
	background-color: $red-light;
	padding: 16px;
	text-align: center;
	margin: 0 auto 16px;
	width: 350px;

	h2 {
		font-weight: $medium;
		@include s-small;
	}

	li {
		@include s-small;
	}

	h2,
	li {
		color: $red-base;
		font-weight: $medium;
		@include s-small;
		line-height: $base-line-height;
	}
}
