.main-layout {
	display: flex;

	.content {
		width: 100%;

		&.medium {
			max-width: 948px;
		}
	}

	main {
		.content-with-sidebar {
			margin-left: 250px;
			margin-top: 72px;

			.content {
				padding: 28px;
			}
		}
	}
	&.no-sidebar {
		background: $blue-grey-base;
		flex-direction: column;
	}
}

.no-sidebar-content {
	padding: 40px;
	background: $white-base;
	margin: 104px 100px 32px 100px;
	border-radius: 15px;
	border: 1px solid $grey-base;
}

.with-changelog {
	display: flex;
	align-items: flex-start;
	gap: $spacing-medium;
	justify-content: space-between;

	.content-with-warning {
		width: 100%;
	}

	.card-warning {
		margin-bottom: $spacing-small;
	}
}

.changelog-card {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 522px;
	padding: $spacing-small;
	border-radius: 12px;
	border: 1px solid $grey-base;
	background-color: $white-base;

	.card-header {
		width: 100%;
	}
}

.vertical-line {
	border-left: 2px solid $grey-base;
}

.content-header {
	margin-bottom: $spacing-medium;
	width: 100%;

	@include tablet {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	h1 {
		font-weight: $semibold;
		@include s-medium;
	}

	.actions {
		@include tablet {
			display: flex;
			gap: 16px;
		}

		button,
		a {
			justify-content: center;
			height: 35px;
			margin-top: 10px;
			white-space: nowrap;
			width: 100%;

			@include tablet {
				margin-top: 0px;
			}
		}
	}
}

.actions-banner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 24px;
	width: 100%;
	background: $grey-lightest;
	padding: 12px;
	border-radius: 8px;

	.secondary-text {
		@include s-tiny;
		margin-right: 20px;
	}
}

// User Preview
.user-preview {
	align-items: center;
	display: flex;
	border-bottom: 1px solid $grey-base;
	padding-bottom: $spacing-big;
	margin-bottom: $spacing-big;

	img {
		background-color: rgba($black-base, 5%);
		margin-right: $spacing-small;
		border-radius: 5px;
		width: $spacing-bigger;
		padding: $spacing-x-small;
	}

	.text-block {
		p:first-child {
			font-weight: $semibold;
		}

		p:last-child {
			@include s-small;
		}
	}
}

.note-block {
	border-radius: 10px;
	display: flex;
	align-items: center;
	background-color: $blue-lightest;
	border: $blue-base;
	gap: 0;
	padding: 15px;

	.note {
		span {
			font-weight: $semibold;
		}
	}

	p {
		line-height: 1.3;
		@include s-small;
	}
	.icon {
		border-radius: 3px;
		height: 18px;
		margin-right: 8px;
		width: 18px;
	}
}

.list-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}
